var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theSide"},[_c('div',{staticClass:"weChart hoverBtn",on:{"mouseover":function($event){_vm.keCodeShow = true,_vm.downCodeShow=false}}},[_vm._m(0),_c('div',{staticClass:"tooltip",class:_vm.keCodeShow && 'tooltip_show'},[_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"try"},[_c('div',[_c('div',{on:{"click":function($event){_vm.show = true}}},[_c('i',{staticClass:"iconfont icon-tianmaoshiyongfuwu"}),_c('div',{staticClass:"tip"},[_vm._v("试用")])])]),_c('div',{staticClass:"down",on:{"mouseover":function($event){_vm.downCodeShow = true,_vm.keCodeShow=false}}},[_vm._m(3),_c('div',{staticClass:"tooltip",class:_vm.downCodeShow && 'tooltip_show'},[_vm._m(4),_vm._m(5)])])]),_c('tryUseDialog',{attrs:{"show":_vm.show,"type":"register"},on:{"close":function($event){_vm.show = false}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-weixin"}),_c('div',{staticClass:"tip"},[_vm._v("微信")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"title1"},[_vm._v("微信咨询 ")]),_c('span',{staticClass:"title2"},[_vm._v("微信扫码咨询")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kfCode"},[_c('div',[_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("@/assets/code1.png")}})]),_c('div',[_vm._v("中国时区")])]),_c('div',[_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("@/assets/code4.png")}})]),_c('div',[_vm._v("义乌(分部)")])]),_c('div',[_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("@/assets/code3.png")}})]),_c('div',[_vm._v("欧洲时区")])]),_c('div',[_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("@/assets/code2.png")}})]),_c('div',[_vm._v("美洲时区")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-xiazai1"}),_c('div',{staticClass:"tip"},[_vm._v("下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"title1"},[_vm._v("下载APP ")]),_c('span',{staticClass:"title2"},[_vm._v("手机扫码下载app")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kfCode"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/downAppCode1.png")}}),_c('div',[_vm._v("PTU2商家版")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/downAppCode2.png")}}),_c('div',[_vm._v("PTU2买家版")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/downAppCode3.png")}}),_c('div',[_vm._v("PDA商家版")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/downAppCode4.png")}}),_c('div',[_vm._v("云点云店小程序")])])])
}]

export { render, staticRenderFns }