<template>
  <div class="theSide">
    <div class="weChart hoverBtn"
      @mouseover="keCodeShow = true,downCodeShow=false">
      <div>
        <!-- @mouseleave="keCodeShow = false" -->
        <!-- <img src="@/assets/sideIcon1.png"> -->
        <i class="iconfont icon-weixin"></i>
        <div class="tip">微信</div>
      </div>

      <div class="tooltip"
        :class="keCodeShow && 'tooltip_show'">
        <div>
          <span class="title1">微信咨询 </span>
          <span class="title2">微信扫码咨询</span>
        </div>
        <div class="kfCode">
          <div>
            <div class="imgBox">
              <img src="@/assets/code1.png">
            </div>

            <div>中国时区</div>
          </div>
          <div>

            <div class="imgBox">
              <img src="@/assets/code4.png">
            </div>
            <div>义乌(分部)</div>
          </div>
          <div>
            <div class="imgBox">

              <img src="@/assets/code3.png">
            </div>
            <div>欧洲时区</div>
          </div>
          <div>
            <div class="imgBox">

              <img src="@/assets/code2.png">
            </div>
            <div>美洲时区</div>
          </div>
        </div>
      </div>
    </div>
    <div class="try">
      <div>
        <div @click="show = true">
          <i class="iconfont icon-tianmaoshiyongfuwu"></i>
          <div class="tip">试用</div>
        </div>
      </div>
      <!-- @mouseleave="downCodeShow = false" -->
      <div class="down"
        @mouseover="downCodeShow = true,keCodeShow=false">
        <div>
          <i class="iconfont icon-xiazai1"></i>
          <div class="tip">下载</div>
        </div>

        <div class="tooltip"
          :class="downCodeShow && 'tooltip_show'">
          <div>
            <span class="title1">下载APP </span>
            <span class="title2">手机扫码下载app</span>
          </div>
          <div class="kfCode">
            <div>
              <img src="@/assets/downAppCode1.png">
              <div>PTU2商家版</div>
            </div>
            <div>
              <img src="@/assets/downAppCode2.png">
              <div>PTU2买家版</div>
            </div>
            <div>
              <img src="@/assets/downAppCode3.png">
              <div>PDA商家版</div>
            </div>
            <div>
              <img src="@/assets/downAppCode4.png">
              <div>云点云店小程序</div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <tryUseDialog :show="show"
      @close="show = false"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import tryUseDialog from '@/components/tryUseDialog'
export default {
  data() {
    return {
      keCodeShow: false,
      downCodeShow: false,
      show: false
    }
  },
  props: {
    changeNum: {
      type: Number,
      default: 0
    }
  },
  watch: {
    changeNum() {
      ;(this.keCodeShow = false), (this.downCodeShow = false)
    }
  },
  components: {
    tryUseDialog
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.theSide {
  position: fixed;
  top: calc(50% - 200px);
  right: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #092e53;
  line-height: 20px;
  z-index: 99;
  .weChart {
    width: 60px;
    height: 90px;
    box-shadow: 0px 2px 10px 0px #d8e3f2;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2e93fe;
    margin-bottom: 10px;
    color: white;
    position: relative;
    .iconfont {
      font-size: 32px;
    }
    .tooltip {
      width: 280px;
      height: 325px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px #d8e3f2;
      border-radius: 8px;
      position: absolute;
      left: 300px;
      top: 10px;
      padding: 17px 19px;
      .title1 {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        display: inline-block;
        margin-right: 8px;
      }
      .title2 {
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
      img {
        width: 100px;
        height: 100px;
        // position: absolute;
        left: 0;
      }
      .kfCode {
        font-size: 10px;
        font-weight: 500;
        color: #333333;
        line-height: 14px;
        display: flex;
        text-align: center;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;
        > div {
          margin-top: 10px;
          height: 130px;
          &:hover {
            img {
              width: 120px;
              height: 120px;
              box-shadow: 0px 2px 10px 0px #d8e3f2;
              left: 0;
              transition-property: width, height;
              transition-duration: 0.3s;
            }
          }
        }
      }
    }
    .tooltip_show {
      left: -300px;
      transition: left 0.8s;
    }
  }
  .try {
    width: 60px;
    height: 180px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #d8e3f2;
    border-radius: 30px;
    > div {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      .iconfont {
        font-size: 32px;
        display: inline-block;
        margin-bottom: 18px;
      }
      .icon-xiazai1 {
        font-size: 28px;
      }
      &:hover {
        background: #2e93fe;
        color: white;
      }
    }
  }
  .tip {
    text-align: center;
  }
}
.down {
  position: relative;
  .tooltip {
    width: 280px;
    height: 325px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #d8e3f2;
    border-radius: 8px;
    position: absolute;
    left: 300px;
    top: 10px;
    padding: 17px 19px;
    .title1 {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      display: inline-block;
      margin-right: 8px;
    }
    .title2 {
      font-size: 10px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
    img {
      width: 100px;
      height: 100px;
    }
    .kfCode {
      font-size: 10px;
      font-weight: 500;
      color: #333333;
      line-height: 14px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      flex-wrap: wrap;
      > div {
        margin-top: 10px;
        height: 130px;
        &:hover {
          img {
            width: 120px;
            height: 120px;
            box-shadow: 0px 2px 10px 0px #d8e3f2;
            left: 0;
            transition-property: width, height;
            transition-duration: 0.3s;
          }
        }
      }
    }
  }
  .tooltip_show {
    left: -300px;
    transition: left 0.8s;
  }
}

@media screen and (max-width: 768px) {
  .theSide {
    display: none;
    // top: calc(50% - 105px);
    // font-size: 0.8rem;
    // .weChart {
    //   text-align: center;
    //   width: 4rem;
    //   height: 6rem;
    //   text-align: left;
    //   > div {
    //     text-align: center;
    //   }
    //   .iconfont {
    //     font-size: 2rem;
    //   }
    //   .tooltip {
    //     width: 22rem;
    //     height: 30rem;
    //     padding: 0.8rem;
    //     img {
    //       width: 10rem;
    //       height: 10rem;
    //     }
    //     .kfCode {
    //       > div {
    //         margin-top: 0.8rem;
    //         &:nth-child(2n) {
    //           margin-left: 0.2rem;
    //         }
    //       }
    //     }
    //   }
    //   .tooltip_show {
    //     left: -23rem;
    //     transition: left 0.8s;
    //   }
    // }
    // .try {
    //   width: 4rem;
    //   height: 12rem;
    //   > div {
    //     height: 6rem;
    //     .iconfont {
    //       font-size: 2rem;
    //     }
    //   }
    // }
  }
  // .try {
  //   > div {
  //     text-align: center;
  //   }
  // }
  // .down {
  //   > div {
  //     text-align: center;
  //   }
  //   .tooltip {
  //     width: 22rem;
  //     height: 30rem;
  //     padding: 0.8rem;
  //     img {
  //       width: 10rem;
  //       height: 10rem;
  //     }
  //     .kfCode {
  //       > div {
  //         margin-top: 0.8rem;
  //         &:nth-child(2n) {
  //           margin-left: 0.2rem;
  //         }
  //       }
  //     }
  //   }
  //   .tooltip_show {
  //     left: -23rem;
  //     transition: left 0.8s;
  //   }
  // }
}
</style>
