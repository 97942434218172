<template>
  <div>
    <div class="mask"
      v-show="show">
      <div class="dialog">
        <img src="@/assets/close.png"
          class="close"
          @click="$emit('close')">
        <div class="dialogTitle">选择地区 </div>
        <a :href="type == 'login' ? 'https://cn.tooerp.cn/#/login' : `https://cn.tooerp.cn/#/register?phone=${phone}`"
          target="_blank">
          <div class="severitem">
            <img src="@/assets/severCN.png">
            中国区
          </div>
        </a>
        <a :href="type == 'login' ? 'https://eu.tooerp.com/#/login':`https://eu.tooerp.com/#/register?phone=${phone}`"
          target="_blank">
          <div class="severitem">
            <img src="@/assets/severEU.png">
            欧洲区
          </div>
        </a>
        <a :href="type == 'login' ? 'https://us.tooerp.com/#/login':`https://eu.tooerp.com/#/register?phone=${phone}`"
          target="_blank">
          <div class="severitem">
            <img src="@/assets/severUS.png">
            美洲区
          </div>
        </a>
        <a :href="type == 'login' ? 'https://af.tooerp.com/#/login':`https://af.tooerp.com/#/register?phone=${phone}`"
          target="_blank">
          <div class="severitem">
            <img src="@/assets/severAF.png">
            南非区
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'login'
    },
    phone: {
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: #333;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog {
  width: 449px;
  height: 500px;
  padding: 26px 30px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  .close {
    position: absolute;
    top: -50px;
    right: 0;
    width: 42px;
    height: 42px;
  }
  .dialogTitle {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
  }
  .severitem {
    width: 390px;
    height: 90px;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 54px;
    img {
      width: 110px;
      height: 76px;
    }
    &:hover {
      background: #f6f6f7;
      box-shadow: 0px 2px 10px 0px #d8e3f2;
    }
  }
}
@media screen and (max-width: 768px) {
  .dialog {
    width: 34rem;
    height: auto;
    .close {
      position: absolute;
      top: -50px;
      right: 0;
      width: 4rem;
      height: 4rem;
    }
    .severitem {
      width: 100%;
      height: 6rem;
      img {
        width: 6rem;
        height: auto;
      }
    }
  }
}
</style>
